<template>
  <HomeLayout>
    <b-container
      class="d-flex flex-column align-items-start justify-content-start px-0"
    >
      <!-- table container row -->
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center table-card-row w-100"
        no-gutters
      >
        <b-card no-body class="shadow-sm border-0 table-container-card w-100">
          <!-- table actions row -->
          <b-row
            class="d-flex flex-row align-items-center justify-content-end w-100 my-1 my-md-2 px-1 py-3"
            no-gutters
          >
            <!-- <b-col
              class="d-flex flex-column align-items-end justify-content-center mr-2 py-2 py-lg-0"
              cols="12"
              md="4"
            > -->
            <b-button
              variant="main-green"
              class="d-flex flex-row align-items-center justify-content-center mx-2 my-2 my-lg-0 px-3 py-2 border-0 rounded-lg add-question-btn"
              pill
              @click="openAddAccountModal($event.target, null)"
              :disabled="getUserRole.role.includes('User')"
            >
              <span class="d-inline ml-2">Add a new account</span>
            </b-button>
            <!-- </b-col> -->
          </b-row>
          <!-- tabs row -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-start w-100 table-container-row"
            no-gutters
          >
            <b-tabs
              active-nav-item-class="font-weight-bold text-main-green"
              class="invoice-tabs"
              nav-class="ml-4 tab-wrapper"
              content-class="mt-3"
            >
              <!-- table row -->
              <b-tab
                title-link-class="tab-nav-link"
                :title="'Accounts ' + totalAccountCount"
                @click="tabChanged('All')"
              >
                <div v-for="account in accounts" :key="account.id">
                  <b-row
                    class="d-flex flex-row align-items-start justify-content-start"
                    no-gutters
                    v-for="sub in account.sub"
                    :key="sub.id"
                    ><b-row
                      class="d-flex flex-row align-items-start justify-content-start px-3 w-100 table-container-row"
                      no-gutters
                      v-if="sub.title === 'Cash and Bank'"
                    >
                      <b-row
                        class="d-flex flex-row align-items-center justify-content-start bg-light px-3 mt-2 mb-4 w-100 card-header-row"
                        no-gutters
                      >
                        <h6 class="py-3 font-weight-bold">
                          {{ sub.title }}
                        </h6>
                      </b-row>
                      <b-row
                        class="d-flex flex-row align-items-start justify-content-start px-3 py-1 w-100"
                        v-for="item in sub.sub"
                        :key="item.id"
                      >
                        <template v-if="!item.archived">
                          <b-col>{{ item.title }} [{{ item.gl_code }}]</b-col>
                          <!-- <b-col cols="8">{{ item.description }} </b-col> -->
                          <!-- <b-col cols="8" v-if="item.description">{{
                          item.description
                        }}</b-col>
                        <b-col cols="8" v-else>{{
                          item.gl_code_description
                        }}</b-col> -->
                          <b-col cols="8">
                            {{
                              item.description && item.gl_code_description
                                ? item.gl_code_description
                                : item.description === null
                                ? item.gl_code_description
                                : item.description || ""
                            }}
                          </b-col>
                          <b-col>
                            <b-row
                              class="d-flex flex-row align-items-center justify-content-between justify-content-md-end w-100 mx-0"
                            >
                              <!-- edit account button -->
                              <b-button
                                v-b-tooltip.hover.left
                                title="Edit Account"
                                class="shadow-none mx-1 border-0 edit-btn"
                                pill
                                @click="
                                  openUpdateAccountModal($event.target, item)
                                "
                                :disabled="getUserRole.role.includes('User')"
                              >
                                <b-icon
                                  icon="pencil-square"
                                  aria-label="Edit Account"
                                  class="edit-icon"
                                ></b-icon>
                              </b-button>
                              <!-- delete account button -->
                              <!-- <b-button
                                v-b-tooltip.hover.left
                                title="Delete Account"
                                class="shadow-none mx-1 border-0 delete-btn"
                                pill
                                @click="editQuizFn(data.value.id)"
                              >
                                <b-icon
                                  icon="trash"
                                  aria-label="Delete Account"
                                  class="delete-icon"
                                ></b-icon>
                              </b-button> -->
                            </b-row>
                          </b-col>
                          <hr
                            style="
                              width: 100%;
                              text-align: center;
                              margin-top: 1.5em;
                            "
                          />
                        </template>
                      </b-row>
                    </b-row>
                  </b-row>
                </div>
              </b-tab>
              <b-tab
                v-for="account in accounts"
                :key="account.id"
                :title="
                  account.title.charAt(0).toUpperCase() +
                  account.title.slice(1) +
                  ' ' +
                  account.count
                "
                title-link-class="tab-nav-link"
                @click="
                  tabChanged(
                    account.title.charAt(0).toUpperCase() +
                      account.title.slice(1)
                  )
                "
              >
                <!-- <AccountTypes /> -->
                <b-row
                  class="d-flex flex-row align-items-start justify-content-start"
                  no-gutters
                  v-for="sub in account.sub"
                  :key="sub.id"
                >
                  <!-- <h1>{{ sub.title }}</h1> -->
                  <b-row
                    class="d-flex flex-row align-items-start justify-content-start px-3 w-100 table-container-row"
                    no-gutters
                  >
                    <!-- header row  -->
                    <b-row
                      class="d-flex flex-row align-items-center justify-content-start bg-light px-3 mt-2 mb-4 w-100 card-header-row"
                      no-gutters
                    >
                      <h6 class="py-3 font-weight-bold">
                        {{ sub.title }}
                      </h6>
                    </b-row>
                    <!-- item row  -->
                    <b-row
                      class="d-flex flex-row align-items-start justify-content-start px-3 py-1 w-100"
                      v-for="item in sub.sub"
                      :key="item.id"
                    >
                      <b-col>{{ item.title }} [{{ item.gl_code }}]</b-col>
                      <!-- <b-col cols="8">{{ item.description }}</b-col> -->
                      <!-- <b-col cols="8" v-if="item.description">{{item.description}}</b-col>
                      <b-col cols="8" v-else>{{item.gl_code_description}}</b-col> -->
                      <b-col cols="8">
                        {{
                          item.description && item.gl_code_description
                            ? item.gl_code_description
                            : item.description === null
                            ? item.gl_code_description
                            : item.description || ""
                        }}
                      </b-col>
                      <b-col>
                        <b-row
                          class="d-flex flex-row align-items-center justify-content-between justify-content-md-end w-100 mx-0"
                        >
                          <!-- edit account button -->
                          <b-button
                            v-b-tooltip.hover.left
                            title="Edit Account"
                            class="shadow-none mx-1 border-0 edit-btn"
                            pill
                            @click="openUpdateAccountModal($event.target, item)"
                            :disabled="getUserRole.role.includes('User')"
                          >
                            <b-icon
                              icon="pencil-square"
                              aria-label="Edit Account"
                              class="edit-icon"
                            ></b-icon>
                          </b-button>
                          <!-- delete account button -->
                          <!-- <b-button
                            v-b-tooltip.hover.left
                            title="Delete Account"
                            class="shadow-none mx-1 border-0 delete-btn"
                            pill
                            @click="editQuizFn(data.value.id)"
                          >
                            <b-icon
                              icon="trash"
                              aria-label="Delete Account"
                              class="delete-icon"
                            ></b-icon>
                          </b-button> -->
                        </b-row>
                      </b-col>
                      <hr
                        style="
                          width: 100%;
                          text-align: center;
                          margin-top: 1.5em;
                        "
                      />
                    </b-row>
                    <!-- add account row -->
                    <b-row
                      class="d-flex flex-row align-items-center justify-content-start px-4 mb-3 w-100"
                    >
                      <b-icon
                        class="text-main-green mr-2"
                        icon="plus-circle-fill"
                        size="sm"
                        aria-label="Plus"
                        font-scale="1.1"
                      ></b-icon>

                      <b-link
                        @click="
                          openAddAccountModal($event.target, {
                            type: account.title,
                            subcategory: sub.title,
                          })
                        "
                        :disabled="getUserRole.role.includes('User')"
                        class="text-left text-main-green font-primary font-weight-normal"
                        >Add a new account</b-link
                      >
                    </b-row>
                  </b-row>
                </b-row>
              </b-tab>
              <!-- Archive Tab -->
              <!-- archiveAccountsCount -->
              <b-tab
                title-link-class="tab-nav-link"
                :title="'Archive ' + archiveAccountsCount"
                @click="tabChanged('All')"
              >
                <div>
                  <b-row
                    class="d-flex flex-row align-items-start justify-content-start"
                    no-gutters
                    v-for="account in archiveAccounts"
                    :key="account.title"
                    ><b-row
                      class="d-flex flex-row align-items-start justify-content-start px-3 w-100 table-container-row"
                      no-gutters
                    >
                      <b-row
                        class="d-flex flex-row align-items-center justify-content-start bg-light px-3 mt-2 mb-4 w-100 card-header-row"
                        no-gutters
                      >
                        <h6 class="py-3 font-weight-bold">
                          {{ account.title }}
                        </h6>
                      </b-row>
                      <b-row
                        class="d-flex flex-row align-items-start justify-content-start px-3 py-1 w-100"
                        v-for="item in account.sub"
                        :key="item._id"
                      >
                        <template v-if="item.archived">
                          <b-col>{{ item.title }} [{{ item.gl_code }}]</b-col>
                          <b-col cols="8">
                            {{
                              item.description && item.gl_code_description
                                ? item.gl_code_description
                                : item.description === null
                                ? item.gl_code_description
                                : item.description || ""
                            }}
                          </b-col>
                          <b-col>
                            <b-row
                              class="d-flex flex-row align-items-center justify-content-between justify-content-md-end w-100 mx-0"
                            >
                              <!-- edit account button -->
                              <b-button
                                v-b-tooltip.hover.left
                                title="Edit Account"
                                class="shadow-none mx-1 border-0 edit-btn"
                                pill
                                @click="
                                  openUpdateAccountModal($event.target, item)
                                "
                                :disabled="getUserRole.role.includes('User')"
                              >
                                <b-icon
                                  icon="pencil-square"
                                  aria-label="Edit Account"
                                  class="edit-icon"
                                ></b-icon>
                              </b-button>
                            </b-row>
                          </b-col>
                          <hr
                            style="
                              width: 100%;
                              text-align: center;
                              margin-top: 1.5em;
                            "
                          />
                        </template>
                      </b-row>
                    </b-row>
                  </b-row>
                </div>
              </b-tab>
            </b-tabs>
          </b-row>
        </b-card>
        <AddAccountModal
          :selectedAccountType="selectedAccountType"
          :accounts="accounts"
          :updateAccount="rowItem"
          :AccountType="this.AccountType"
          v-if="showAddAccountModalKey"
          @reloadFn="callInitFn"
        />
      </b-row>
      <b-overlay :show="isLoading" z-index="4" no-wrap></b-overlay>
    </b-container>
  </HomeLayout>
</template>

<script>
// @ is an alias to /src
import HomeLayout from "@/layout/HomeLayout";

// services
import {
  GetAllChartOfAccounts,
  GetCashAndBank,
} from "@/services/transactions.service";
import AddAccountModal from "@/components/ChartOfAccounts/modals/AddAccountModal";
import { mapGetters } from "vuex";

export default {
  name: "ChartOfAccountsV2",
  components: {
    HomeLayout,
    AddAccountModal,
  },
  data() {
    return {
      isShowDeleteItemRow: false,
      isShowAddAccountItemRow: false,
      showAddAccountModalKey: Number(new Date()) + 100,
      selectedAccountType: null,
      rowItem: null,
      rowIndex: null,
      filterDropDownShow: false,
      AccountType: "All",
      accounts: {},
      archiveAccounts: [],
      archiveAccountsCount: null,
      //   items: [
      //     {
      //       key: 1,
      //       name: "Cash on Hand",
      //       description:
      //         "Cash you haven’t deposited in the bank. Add your bank and credit card accounts to accurately categorize transactions that aren't cash.",
      //     },
      //     {
      //       key: 2,
      //       name: "Credit Card",
      //       description:
      //         "Cash you haven’t deposited in the bank. Add your bank and credit card accounts to accurately categorize transactions that aren't cash.",
      //     },
      //   ],
      //   assets: {
      //     id: null,
      //     title: null,
      //     cash_and_bank: {
      //       id: null,
      //       title: "Hello",
      //     },
      //     money_in_transit: {
      //       id: null,
      //       title: null,
      //     },
      //     expected_from_customers: {
      //       id: null,
      //       title: null,
      //     },
      //   },
      //   equity: {
      //     id: null,
      //     title: null,
      //   },
      //   expenses_acc: {
      //     id: null,
      //     title: null,
      //   },
      //   income_acc: {
      //     id: null,
      //     title: null,
      //     discount: {
      //       id: null,
      //       title: null,
      //     },
      //     income: {
      //       id: null,
      //       title: null,
      //     },
      //   },
      //   liability: {
      //     id: null,
      //     title: null,
      //   },
      isLoading: false,
      totalAccountCount: 0,
    };
  },
  async mounted() {
    await this.initFn();
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      if (modalId == "add-account-modal") this.onCloseAddAccountModal();
    });
  },
  methods: {
    async initFn() {
      try {
        this.isLoading = true;
        this.totalAccountCount = 0;
        let { data } = await GetAllChartOfAccounts();
        await GetCashAndBank().then((res) => {
          this.totalAccountCount = res.data.data.length;
        });
        this.accounts = data.chart.chart;
        this.archiveAccounts = data.chart.archiveAccounts.map(
          (account) => account
        );
        this.archiveAccountsCount = data.chart.archiveTotal;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    openDeleteCustomerModal(item, button) {
      // this.$store.dispatch("setSelectedItem", item);
      this.isShowDeleteItemRow = true;
      this.rowItem = item;
      this.$root.$emit("bv::show::modal", "delete-customer-row-modal", button);
    },
    onCloseDeleteCustomerModal() {
      this.isShowDeleteItemRow = false;
    },
    callInitFn() {
      this.showAddTransactionModalKey = Number(new Date()) + 100;
      this.initFn();
    },
    editCustomerFn(id) {
      this.$router.push("/customers/edit/" + id);
    },
    openAddAccountModal(button, selectedAccountType) {
      this.modalThatBtn = button;
      this.rowItem = null;
      this.selectedAccountType = selectedAccountType;
      this.isShowAddAccountItemRow = true;
      this.$root.$emit("bv::show::modal", `add-account-modal`, button);
    },
    openUpdateAccountModal(button, item) {
      this.modalThatBtn = button;
      this.rowItem = item;
      this.isShowAddAccountItemRow = true;
      this.$root.$emit("bv::show::modal", `add-account-modal`, button);
    },
    onCloseAddAccountModal() {
      this.rowItem = null;
      this.selectedAccountType = null;
      this.isShowAddAccountItemRow = false;
    },
    tabChanged(tab) {
      this.AccountType = tab;
    },
  },
  computed: {
    ...mapGetters({
      getUserRole: "auth/getUserRole",
    }),
  },
};
</script>

<style scoped>
/* table card */
.table-card-row {
  height: 100%;
}

.table-card-row .table-container-card {
  background-color: var(--white);
  width: 100%;
  height: auto;
  border-radius: 0;
  border: 1px solid var(--light);
}

.table-card-row .table-container-card >>> .tab-wrapper {
  /* border-bottom: 0.5px solid var(--gray-750); */
  border-bottom: none;
}

.table-card-row .table-container-card >>> .invoice-tabs {
  width: 100%;
  font-size: 1rem;
  overflow: hidden;
  /* background-color: var(--ghost-white); */
}

.table-card-row .table-container-card >>> .invoice-tabs .tab-nav-link {
  font-size: 0.9rem;
  padding-right: 20px;
  white-space: nowrap;
  background: transparent;
  border: transparent;
  color: var(--gray-750);
  /* border-bottom: 1px solid var(--prime-gray); */
}

.table-card-row .table-container-card >>> .invoice-tabs .tab-nav-link:hover {
  background-color: var(--light);
  color: #0b9b7d;
}

.table-card-row .table-container-card >>> .invoice-tabs .tab-nav-link.active {
  background-color: var(--light);
  border-bottom: 3px solid #0b9b7d;
}

.table-card-row .filter-dropdown,
.table-card-row .add-question-btn {
  height: 45px;
}

.table-card-row .add-question-btn:hover {
  background: #0b9b7d;
  box-shadow: inset 0px 0px 10px #ffffff;
}

.table-card-row .filter-dropdown >>> .filter-dropdown-menu {
  height: auto;
  min-width: 200px;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}

.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item.active,
.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item:active {
  background-color: var(--light);
}

/* table container */
.table-container-row {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}

/* table question action buttons */
/* edit icon button (default state) */
.table-container-row .edit-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.table-container-row .edit-btn > .edit-icon {
  color: #0b9b7d;
  transition: color 200ms ease-in;
}

/* edit icon button (hover state) */
.table-container-row .edit-btn:hover {
  background-color: #0b9b7d;
}

.table-container-row .edit-btn:hover > .edit-icon {
  color: var(--white);
}

/* delete icon button (default state) */
.table-container-row .delete-btn {
  width: 40px;
  height: 40px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.table-container-row .delete-btn > .delete-icon {
  color: var(--danger);
  transition: color 200ms ease-in;
}

/* delete icon button (hover state) */
.table-container-row .delete-btn:hover {
  background-color: var(--danger);
}

.table-container-row .delete-btn:hover > .delete-icon {
  color: var(--white);
}

/* scrollbar size fix for webkit browsers (chrome/safari) */
.table-container-row::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}

.table-container-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}

.table-container-row::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .table-card-row .filter-dropdown,
  .table-card-row .export-dropdown {
    min-width: auto;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  /* .insight-cards-row {
    height: auto;
  } */
}
</style>
